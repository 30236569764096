.ant-layout-header {
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: 40px;
            float: left;
            width: auto; } } }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 13px !important; }

.pollutant-selector {
    position: fixed;
    top: 5.55em;
    right: 3.5em;
    z-index: 5;
    width: 200px;
    outline: none !important;
    box-shadow: none !important;

    span.op-4 {
        font-size: 8px !important; } }

.mode-controller {
    position: fixed;
    bottom: 6em;
    right: 1em; }

.sidebar {
    background: #001629;
    position: fixed;
    left: 0;
    top: 0;
    width: 480px;
    height: 100%;
    box-shadow: 0px 10px 7px #0000001f;
    overflow-y: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent; }

    &::-webkit-scrollbar {
        width: 3px !important;
        background-color: transparent; }

    &::-webkit-scrollbar-thumb {
        background-color: transparent; }

    .wrapper {
        padding: 23px;
        color: white;

        .ant-input {
            border: 0; }

        .ant-menu {
            color: white;
            background: transparent; }

        .temporal-data {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            background: #ffffff21;
            border-radius: 5px; }

        .ant-card {
            background: transparent;
            color: white;
            border: 0; }

        .card-list {
            .ant-card-grid {
                width: 100%;
                border: 0;
                box-shadow: none;
                background: #ffffff21;

                &:not(:first-child) {
                    margin-top: 10px; } } }

        .ant-typography {
            color: white !important; }

        .ant-input {
            background: #ffffff21;
            color: white;
            padding: 12px;
            border-radius: 3px;

            &:focus {
                box-shadow: none; } }

        .ant-divider {
            border-color: #ffffff21; } } }

p.disclaimer {
    font-size: 11px; }

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: black; }

.vector-scale {
    left: 412px;
    top: 5.55em;
    right: unset !important;
    bottom: unset !important; }

.overlay-temporal {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000005c;
    left: 0; }

.copyright-footer {
    position: fixed;
    bottom: 0.7em;
    right: 1em;
    font-size: 12.5px;
    font-weight: 600; }

@media only screen and (max-width: 1024px) {
    .sidebar {
        display: none;
        width: 90%;
        z-index: 9; }

    .desktop-display {
        display: none; }

    header .ant-row {
        height: 100%; }

    .ant-layout-header {
        padding: 0 10px; }

    .ant-layout-header .logo {
        justify-content: flex-end; } }
