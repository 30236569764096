.map {
  position: relative;
  height: calc( 100vh - 64px);
  width: 100%; }

// OL map controls to right side
.ol-zoom {
  left: unset !important;
  right: 0.5rem; }

#color-legend-parent {
  max-width: 100%;
  overflow: hidden;
  padding: 0 1rem;
  position: absolute;
  bottom: 30px;
  right: 0px;

  svg {
    margin: 0 auto; } }
