
.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 58%;
    vertical-align: top;
    height: 0;
    overflow: hidden;

    &.placeholder-temporal .ui.placeholder {
        max-width: inherit; }

    .chartXAxisLabel {
        fill: grey;
        font-weight: bold; }

    .chartYAxisLabel {
        fill: grey;
        font-weight: bold; }

    .svg-content {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        .domain {
            stroke-width: 0px; } }

    .tick text {
        font-size: 2.3em; }
    .tick {
        opacity: 0.5; }

    .svg-content {
        font-size: 9px; }

    .overlay {
        opacity: 0; } }

.stop-top {
    stop-color: #91b0c700; }
.stop-bottom {
    stop-color: rgba(255, 255, 255, 0); }
.x-hover-line {
    opacity: 0.2; }

.time-series-chart-highlight {
    fill: #f5f5f5; }

.time-series-chart-highlight-o {
    fill: v#ecf2f6;
    stroke: #f5f5f5;
    stroke-width: 3.5px; }

.time-series-chart {
    stroke: #f5f5f5; }

.time-series-chart-area {
    animation: fadeGraphGradient 1.5s ease-in-out forwards 1s;
    opacity: 0; }

.line-text {
    font-size: 30px;
    fill: white; }

.x-hover-line {
    opacity: 0.2; }

.draw-graph {
    animation: dash 1.5s ease-in-out forwards 0.5s; }

@keyframes fadeGraphGradient {
    to {
        opacity: 1; } }

@keyframes dash {
    to {
        stroke-dashoffset: 0; } }
